import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { Article, ArticleSection } from "../../styledComponents/article"
import {
  Section
} from "../../styledComponents/section"
import { HeroTextImageWrapper, HeroText, HeroHeader, HeroDescription } from "../../styledComponents/heroBanner"

const WebsiteAudit = ({ data, location, createCookie }) => {
    return (
      <Layout location={location}>
        <Seo 
          title="Get Your Website Audited for SEO Accessibility, Usability, and Page Speed | Improve Your Online Performance" 
          description="Get a comprehensive website audit for SEO accessibility, usability, and page speed to improve your website's online performance. Our experts will analyze your website and provide you with actionable insights to help you improve your website's overall ranking, visibility, and user experience. Don't wait any longer, start optimizing your website today!"
          image=""
          url="/services/website-audit"
        />
          <HeroTextImageWrapper backgroundColor="var(--dark-blue-mid)" gridRowGap="0">
            <StaticImage
              loading="eager"
              backgroundcolor="#002F4A"
              layout="fullWidth"
              src={
                "../../images/website-design.png"
              }
              formats={["auto", "webp", "avif"]}
            />
            <HeroText fontColor="var(--white)">
                <HeroHeader level={1}  fontColor="var(--white)">
                Professional website design services
                </HeroHeader>
                <HeroDescription>Your path to the perfect hosting plan starts here</HeroDescription>
            </HeroText>
          </HeroTextImageWrapper>
          <Article>
            <ArticleSection>
             
            <p>Are you looking for a professional, custom-built website that can help grow your online presence? Look no further! Our website design services offer the latest technology, stunning designs and expert support to help you reach your online goals.</p>

            <Section background="var(--mid-blue-mid)" type="pullout">
              <h2>Custom Built Websites That Fit Your Unique Needs</h2>
            </Section>

            <p>Our team of experts will work with you to understand your vision, business goals and target audience. We will then create a custom-built website that perfectly fits your needs and provides the best user experience for your visitors. From small businesses to large enterprises, we can help you achieve the online success you desire.</p>

            <h2>Stay Ahead of the Game with the Latest Technology</h2>

            <p>We use the latest technology and cutting-edge design trends to create websites that are not only beautiful, but also fast, secure and optimized for search engines. Our team stays up-to-date with the latest advancements in web design, ensuring that your website will always be ahead of the curve.</p>

            <h2>Packages for Every Budget</h2>

            <p>Whether you're just starting out or looking for a professional, high-end website, we have a package that fits your budget. Our starter package starts at just £399 + VAT, while our professional package is priced at £999 + VAT. No matter which package you choose, you can be sure that you're getting the best value for your money.</p>

            <h2>Expert Support and Maintenance</h2>

            <p>Our team of experts is always here to help you with any questions or concerns you may have about your website. We also offer ongoing maintenance and support services to ensure that your website is always running smoothly and stays up-to-date with the latest technology.</p>

            <h2>The Power of a Professional Website</h2>

            <p>A professional website is a crucial aspect of your online presence and can help you reach a wider audience, increase your brand recognition and grow your business. Don't settle for a generic, cookie-cutter website – choose our custom-built website design services and take your online presence to the next level.</p>

            <h2>Get Started Today</h2>

            <p>Don't wait any longer to unleash your online potential. Contact us today to learn more about our website design services and how we can help bring your vision to life. Let's work together to create a website that truly represents your business and helps you achieve your online goals.</p>

            </ArticleSection>
          </Article>
      </Layout>
    )
}

export default WebsiteAudit
